@import "nlib/config";

.accountsPage {
  .errorAlert {
    margin-top: $contentDefaultIndent;
  }
  .noDataContent {
    display: flex;
    flex: auto;
    padding: $contentDefaultIndent * 2;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
  }
}
