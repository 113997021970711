@import "nlib/config";

.connectInstitutionWindow {
  .dialogClassName {
    width: 40rem;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 33.33333%;
      padding: $contentDefaultIndent / 2;
      white-space: nowrap;
      .content {
        display: flex;
        align-items: center;
        padding: $contentDefaultIndent;
        border-radius: $uiBorderRadius;
        cursor: pointer;
        &:hover {
          background-color: darken($uiBackgroundColor, 5%);
        }
        &:active {
          background-color: darken($uiBackgroundColor, 3%);
        }
        &.connected {
          background-color: darken($uiBackgroundColor, 2%);
          svg {
            display: block;
          }
        }
      }
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        overflow: hidden;
        border-radius: $uiBorderRadius;
        img {
          max-width: 100%;
        }
      }
      .name {
        flex: auto;
        overflow: hidden;
        font-weight: 500;
        text-overflow: ellipsis;
      }
      svg {
        display: none;
        color: $uiPositiveColor;
        font-size: 1rem;
      }
    }
  }
}
