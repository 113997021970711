@import "nlib/config";
@import "animations";

.institution {
  align-items: flex-start !important;
  margin-bottom: $contentDefaultIndent * 1.5;
  box-shadow: none !important;
  .cardBody {
    width: 100%;
  }
  + .institution {
    flex: auto !important;
  }
  button {
    width: 2rem;
    padding: 0;
    border: 1px solid transparent;
    line-height: 1;
    svg {
      color: $uiWhiteColor;
      font-size: 1rem;
    }
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: $contentDefaultIndent;
    white-space: nowrap;
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
      overflow: hidden;
      border-radius: $uiBorderRadius;
      img {
        max-width: 100%;
      }
    }
    .name {
      margin-right: $contentDefaultIndent * 2;
      overflow: hidden;
      font-size: 1rem;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .reconnectButton {
      margin-right: $contentDefaultIndent;
      margin-left: $contentDefaultIndent;
    }
    .expiresAt {
      display: flex;
      align-items: center;
      margin-left: auto;
      padding-right: $contentDefaultIndent;
      .successIcon {
        margin-right: 16px;
        color: $uiPositiveColor;
        font-size: 1rem;
      }
      .text {
        color: $uiNeutralColor;
        &.warning {
          color: $uiWarningColor;
          font-weight: 500;
        }
        &.expired {
          color: $uiNegativeColor;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
    .action {
      margin-right: $contentDefaultIndent;
      margin-left: $contentDefaultIndent * 2;
    }
  }
  .table {
    padding-left: 1.5rem;
    border: none;
    white-space: nowrap;
    .nameCell {
      flex: 20;
      width: 20px;
      padding-left: $contentDefaultIndent * 2;
      a {
        text-decoration: none;
      }
    }
    .sourceCell {
      flex: 10;
      width: 10px;
    }
    .balanceCell {
      flex: 6;
      justify-content: flex-end;
      width: 6px;
      padding-right: 1rem;
    }
    .transactionsCell {
      flex: 5;
      width: 5px;
    }
    .lastUpdatedAtCell {
      flex: 5;
      width: 5px;
    }
    .actionsCell {
      flex: none;
      justify-content: flex-end;
      width: 5rem;
      .deleteButton {
        svg {
          color: $uiNegativeColor;
        }
      }
    }
  }
  [data-wait] {
    animation: rotate 2s linear infinite;
  }
}
